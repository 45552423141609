<template>
  <div>
    <div class="sale">
      <ul>
        <li>
          <h2>服务承诺</h2>
          <p>免费送货，一年保修，全程安装咨询</p>
        </li>
        <li>
          <h2>7天无理由退货</h2>
          <p>自收到购买商品7天内无理由退货</p>
        </li>
        <li>
          <h2>30天免费换货</h2>
          <p>
            购买收货30天内，因质量问题，免费换货<br />
            智能锁、配件类商品您可以拨打宇起售后电话，与售后工作人员共同完成换货服务。
          </p>
        </li>
        <li>
          <h2>客户回访</h2>
          <p>
            客户回访是服务的重要一环，重视客户回访，<br />
            充分利用每次机会，了解更多需求，才能用心服务好每一位客户；<br />
            对于宇起，不仅仅是一句口号，每一位宇起的客户，交易的完成不代表结束，服务依旧继续。<br />
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.sale {
  text-align: center;
  margin-top: 2.6042vw;
  h2 {
    font-size: 1.5625vw;
    font-weight: 500;
    margin: 1.0417vw 0;
  }
  p {
    font-size: 1.25vw;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }
  li {
    margin: 2.6042vw auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ebeef5;
    padding: 1.6042vw 0;
  }
}
</style>
